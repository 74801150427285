import styled from 'styled-components'

export const tableStyles = styled.div`
/* This is required to make the table full-width */
display: block;
max-width: 100%;
padding-bottom: 3rem;

/* This will make the table scrollable when it gets too small */
.tableWrap {
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  border-bottom: 1px solid black;
}

table {
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 0;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th,
  td {
    margin: 0;
    padding: 0.5rem;
    border-bottom: 1px dotted #dee3ed;
    border-right: 1px dotted #dee3ed;

    /* The secret sauce */
    /* Each cell should grow equally */
    width: 1%;
    /* But "collapsed" cells should be as small as possible */
    &.collapse {
      width: 0.0000000001%;
    }

    :last-child {
      border-right: 0;
    }
  }
}

.pagination {
  padding: 0.5rem;
}
`