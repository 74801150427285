import React from "react";
import { Tag } from "antd";

export const CellStatus = ({ cell }) => {
	let resaLignes = cell.row.original.Ligne !== undefined || cell.row.original.Ligne !== "" ? cell.row.original.Ligne : cell.row.original.Lignes;
	return (
		<div style={{ textAlign: "center" }}>
			{resaLignes !== undefined && resaLignes.length > 1 ? (
				<Tag color="orange">multi</Tag>
			) : (
				<Tag color="blue">simple</Tag>
			)}
		</div>
	);
};
