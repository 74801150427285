export const magasins = {
	"zago-annecy": {
		marque: "ZAGO",
		magcode: "1740349",
		nom: "ZAGO ANNECY",
		horaire:
			"Du lundi au vendredi de 10h00 à 12h30 et de 14h à 19h et le samedi de 10h00 à 13h00",
	},
	"zago-sallanches": {
		marque: "ZAGO",
		magcode: "1740419",
		nom: "ZAGO SALLANCHES",
		horaire:
			"Du lundi au vendredi de 10h00 à 12h30 et de 14h à 19h et le samedi de 10h00 à 19h00",
	},
};
