import React from "react";
import { useAsyncDebounce } from "react-table";
import { AiFillCloseCircle } from "react-icons/ai";

export const GlobalFilter = ({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	lastUpdate,
}) => {
	const count = preGlobalFilteredRows.length;
	const [value, setValue] = React.useState(globalFilter);
	
	const onSearch = useAsyncDebounce((val) => {
		setGlobalFilter(val || undefined);
	}, 1500);

	return (
		<div
			style={{
				display: "grid",
				justifyContent: "space-between",
				gridTemplateColumns: "repeat(3, 1fr)",
				gridGap: "10px",
			}}
		>
			<div
				style={{
					gridColumn: "1/4",
					backgroundColor: "#fff",
					padding: 15,
					borderRadius: 10,
					marginBottom: 15,
				}}
			>
				<div style={{ padding: 5 }}>
					<button
						title="Reset"
						style={{
							backgroundColor: "white",
							border: 0,
							cursor: "pointer",
						}}
						onClick={() => onSearch("")}
					>
						<AiFillCloseCircle
							size={20}
							style={{ verticalAlign: "sub" }}
						/>
					</button>
					<input
						value={value}
						onChange={(e) => {
							setValue(e.target.value);
							onSearch(e.target.value);
						}}
						onKeyDown={(e) => {
							if (e.key === "Enter") {
								onSearch(value);
							}
						}}
						placeholder={`Rechercher ${count} lignes...`}
						style={{
							width: "92%",
							fontSize: "1.1rem",
							border: "0",
							backgroundColor: "#EEE",
							borderRadius: "8px",
							padding: "0.2rem",
						}}
					/>
				</div>
			</div>
		</div>
	);
};
