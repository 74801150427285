import { db } from "./init-firebase";
import { doc, getDoc } from "firebase/firestore";

export const getLastUpdate = async (magasin) => {
	const resa = doc(db, magasin, "lastUpdate");
	const docSnap = await getDoc(resa);
	if (docSnap.exists()) {
		return docSnap.data().date.seconds;
	} else {
		console.log("No such document!");
	}
};
