import React from "react";
import { GlobalFilter } from "./GlobalFilter";
import { DefaultColumnFilter } from "./DefaultColumnFilter";
import { useSortBy, useTable, useFilters, useGlobalFilter } from "react-table";
import { matchSorter } from "match-sorter";
import { CellVente } from "./CellVente";
import { CellClient } from "./CellClient";
import { CellCreation } from "./CellCreation";
import { CellStatus } from "./CellStatus";
import { CellTelephone } from "./CellTelephone";

export const SimpleDataTable = (props) => {
	const data = props.data;
	const lastUpdate = props.lastUpdate;
	const magasin = props.magasin;

	function fuzzyTextFilterFn(mathrows, id, filterValue) {
		return matchSorter(mathrows, filterValue, {
			keys: [(row) => row.values[id]],
		});
	}

	fuzzyTextFilterFn.autoRemove = (val) => !val;

	const columns = React.useMemo(
		() => [
			{
				Header: "Vente",
				accessor: "Vente",
				Cell: ({ cell }) => CellVente({ cell, magasin }),
				sortType: (a, b) => (parseInt(a.original.Vente) > parseInt(b.original.Vente) ? 1 : -1),
			},
			{
				Header: "Client",
				accessor: "Client",
				Cell: ({ cell }) => CellClient({ cell }),
			},
			{
				Header: "Création",
				accessor: "formatedDate",
				Cell: ({ cell }) => CellCreation({ cell }),
				sortType: (a, b) => {
					let datePartsA = a.original.formatedDate.split("/");
					let datePartsB = b.original.formatedDate.split("/");
					let a1 = new Date(+datePartsA[2], datePartsA[1] - 1, +datePartsA[0]).getTime();
					let b1 = new Date(+datePartsB[2], datePartsB[1] - 1, +datePartsB[0]).getTime();
					if (a1 < b1) return 1;
					else if (a1 > b1) return -1;
					else return 0;
				},
			},
			{
				Header: "Nom",
				accessor: "Nom",
			},
			{
				Header: "Prenom",
				accessor: "Prenom",
			},
			{
				Header: "Ville",
				accessor: "Ville",
			},
			{
				Header: "Etat",
				accessor: "isCompleted",
				Cell: ({ cell }) => CellStatus({ cell }),
			},
			{
				Header: "Téléphone",
				accessor: "Portable",
				Cell: ({ cell }) => CellTelephone({ cell, magasin }),
			},
			{
				Header: "Vendeur",
				accessor: "Vendeur",
			},
		],
		[]
	);

	const filterTypes = React.useMemo(
		() => ({
			// Add a new fuzzyTextFilterFn filter type.
			fuzzyText: fuzzyTextFilterFn,
			// Or, override the default text filter to use
			// 'startWith'
			text: (fliterRows, id, filterValue) => {
				return fliterRows.filter((row) => {
					const rowValue = row.values[id];
					return rowValue !== undefined
						? String(rowValue)
								.toLowerCase()
								.startsWith(String(filterValue).toLowerCase())
						: true;
				});
			},
		}),
		[]
	);

	const defaultColumn = React.useMemo(
		() => ({
			// Let's set up our default Filter UI
			Filter: DefaultColumnFilter,
		}),
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		state,
		preGlobalFilteredRows,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			defaultColumn, // Be sure to pass the defaultColumn option
			filterTypes,
			initialState: {
				sortBy: [
					{
						id: "Vente",
						desc: true,
						enableMultiSort: false,
						
					},
				],
			},
		},
		useFilters, // useFilters!
		useGlobalFilter,
		useSortBy
	);

	const sortedDesc = (column) => (column.isSortedDesc ? " ↓" : " ↑");

	return (
		<>
			<GlobalFilter
				preGlobalFilteredRows={preGlobalFilteredRows}
				globalFilter={state.globalFilter}
				setGlobalFilter={setGlobalFilter}
				lastUpdate={lastUpdate}
			/>
			<table
				{...getTableProps()}
				style={{
					border: 0,
					backgroundColor: "white",
					borderRadius: 10,
				}}
			>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								// Add the sorting props to control sorting. For this example
								// we can add them into the header props
								<th
									{...column.getHeaderProps(
										column.getSortByToggleProps()
									)}
									style={{
										padingRight: 10,
										cursor: "pointer",
									}}
								>
									{column.render("Header")}
									{/* Add a sort direction indicator */}
									<span>
										{column.isSorted
											? sortedDesc(column)
											: "  "}
									</span>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, _) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									if (cell.row.original.Client != null) {
										return (
											<td {...cell.getCellProps()}>
												{cell.render("Cell")}
											</td>
										);
									}
									return null;
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</>
	);
};
