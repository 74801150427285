import React from "react";
import ModalTable from "../Modal/ModalTable";
import { Tag } from "antd";

export const CellVente = ({ cell, magasin }) => {
	return (
		<div style={{ textAlign: "center" }}>
			{!cell.row.original.mail ? (
				<ModalTable resa={cell.row.original} magasin={magasin} />
			) : (
				<Tag color="magenta">
					<span role="img" aria-label="email">
						📧
					</span>{" "}
					ENVOYE
				</Tag>
			)}
		</div>
	);
};
