import React, { useState } from "react";
import { Modal, Button } from "antd";
import ResaLignesTable from "../Table/ResaLignesTable";
import { MailOutlined, CheckOutlined } from "@ant-design/icons";
import { magasins } from "../../services/magasinListe";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";

const ModalTable = (props) => {
  ModalTable.propTypes = {
    resa: PropTypes.object,
    magasin: PropTypes.string,
  };
  const resa = props.resa;
  const magasin = props.magasin;
  const [token, setToken] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const showModal = () => {
    setIsModalVisible(true);
  };

  React.useEffect(() => {
    getAccessToken();
  }, []);

  async function getAccessToken() {
    try {
      setToken(await getAccessTokenSilently());
    } catch (e) {
      console.log(e);
    }
  }

  function mailSended() {
    let url =
      process.env.REACT_APP_API_GATEWAY_URL +
      magasin +
      "/reservation/" +
      resa.Vente +
      "/email";
    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        return response.clone().json();
      })
      .then((res) => {
        window.location.reload(false);
        return res;
      });
  }

  function storeHours(magasin) {
    if (magasin === magasins["zago-annecy"].magcode) {
      return encodeURIComponent(magasins["zago-annecy"].horaire);
    } else if (magasin === magasins["zago-sallanches"].magcode) {
      return encodeURIComponent(magasins["zago-sallanches"].horaire);
    } else if (magasin === magasins["zago-st-priest"].magcode) {
      return encodeURIComponent(magasins["zago-st-priest"].horaire);
    }
  }

  const handleOk = (_e) => {
    let items = [];
    let mail = resa.Email;
    let subject = `Des nouvelles de votre commande numéro ${resa.Vente}`;
    let resaLignes = resa.Ligne !== undefined ? resa.Ligne : resa.Lignes;
    if (resaLignes.length === 1) {
      items =
        "%0D%0A" +
        encodeURIComponent(resaLignes[0].Quantite) +
        "%20X%20" +
        encodeURIComponent(resaLignes[0].Designation) +
        "  %28r%C3%A9f%C3%A9rence%20%3A%20" +
        encodeURIComponent(resaLignes[0].BarCode) +
        "%20%29";
    } else {
      resaLignes.forEach((lineItem) => {
        items.push(
          "%0D%0A" +
            encodeURIComponent(lineItem.Quantite) +
            "%20X%20" +
            encodeURIComponent(lineItem.Designation) +
            "  %28r%C3%A9f%C3%A9rence%20%3A%20" +
            encodeURIComponent(lineItem.BarCode) +
            "%20%29"
        );
      });
    }
    let body =
      encodeURIComponent("Cher client,") +
      "%0D%0A" +
      "%0D%0A" +
      encodeURIComponent(
        "Nous avons le plaisir de vous informer de la réception de tout ou une partie de votre commande dans notre magasin."
      ) +
      "%0D%0A" +
      encodeURIComponent("Liste des Produits disponibles en retrait : ") +
      "%0D%0A" +
      items.toString() +
      "%0D%0A" +
      "%0D%0A" +
      encodeURIComponent(
        "Nous vous invitons à venir la récupérer sous dizaine pendant les horaires d'ouverture suivants :"
      ) +
      "%0D%0A" +
      "%0D%0A" +
      storeHours(magasin) +
      "%0D%0A" +
      "%0D%0A" +
      encodeURIComponent(
        "Pour les colis volumineux, nous vous rappelons de prévoir un véhicule adapté."
      ) +
      "%0D%0A" +
      "%0D%0A" +
      encodeURIComponent("Merci et à très vite,");

    window.location = `mailto:${mail}?subject=${encodeURIComponent(
      subject
    )}&body=${body}`;
    mailSended();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const title =
    resa.Vente + " : " + resa.Nom + " " + resa.Prenom + " - " + resa.Email;

  return (
    <>
      {props.resa.Email !== "" ? (
        <Button onClick={showModal}>
          <span role="img" aria-label="email">
            📧
          </span>
          <strong>{props.resa.Vente}</strong>
        </Button>
      ) : (
        <strong>{props.resa.Vente}</strong>
      )}
      <Modal
        title={title}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button icon={<CheckOutlined />} key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button
            key="submit"
            icon={<MailOutlined />}
            type="primary"
            onClick={handleOk}
          >
            Envoyer un mail
          </Button>,
        ]}
        width={1000}
      >
        {props.resa.Ligne && <ResaLignesTable data={props.resa.Ligne} />}
        {props.resa.Lignes && <ResaLignesTable data={props.resa.Lignes} />}
      </Modal>
    </>
  );
};

export default ModalTable;
