import React from "react";
import "../../App.css";

function LoginCard({ loginWithRedirect, isAuthenticated }) {
	return (
		<div className="login">
			<div className="pre-login-card">
				<div>
					<img
						className="button-div"
						src="./zago.png"
						width="180"
						alt="logo"
					/>
					<h4 className="subtitle">INSTOCK V.2.0</h4>
				</div>
				<div className="button-div">
					<button
						className="auth0-button"
						onClick={loginWithRedirect}
					>
						<img src="./auth0_logo.png" width="50" alt="Auth0" />
						<h4 className="text-button-connexion">CONNEXION</h4>
					</button>
				</div>
				<p className="text-connection">
					{isAuthenticated
						? "Vous êtes connecté"
						: "Vous n'êtes pas connecté"}
				</p>
			</div>
		</div>
	);
}

export default LoginCard;
