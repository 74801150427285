import React from "react";
import { Tag } from "antd";
import ModalSMS from "../Modal/ModalSMS";

export const CellTelephone = ({ cell, magasin }) => {
	return (
		<div style={{ textAlign: "center" }}>
			{!cell.row.original.sms ? (
				<ModalSMS resa={cell.row.original} magasin={magasin} />
			) : (
				<Tag color="magenta">
					<span role="img" aria-labelledby="phone">
						📱
					</span>{" "}
					ENVOYE
				</Tag>
			)}
		</div>
	);
};
