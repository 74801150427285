import React from "react";

export const Header = ({ image }) => {
	return (
		<div
			className="flex justify-center"
			style={{
				display: "flex",
				justifyContent: "center",
				marginBottom: "1rem",
				height: "5rem",
				backgroundColor: "#FFFFFF",
				borderRadius: "10px",
			}}
		>
			<img src={image} height="90%" alt="logo" />
		</div>
	);
};
